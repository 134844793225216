import config from "@/config";
import { get, del, post, patch } from "@/requests"

export async function getPatientsData() {
    return get(`${config.BACK_IP}/patient`)
}

export async function getPatientData(id) {
    return get(`${config.BACK_IP}/patient/${id}`)
}

export async function getPacientLimit(pacient_id) {
    return get(`${config.BACK_IP}/patient/${pacient_id}/limit`)
}

export async function getPacientAlert(pacient_id) {
    return get(`${config.BACK_IP}/patient/${pacient_id}/alert`)
}

export async function assignPatientPathology(id, pathologyIDSelection, comment) {
    return post(`${config.BACK_IP}/patient/${id}/pathology/${pathologyIDSelection}`, {comment})
}

export async function assignPatientAlert(id, alertIDSelection, alertLimit) {
    return post(`${config.BACK_IP}/patient/${id}/alert/${alertIDSelection}`, { alert_limit: alertLimit })
}

export async function addPatientLimits(id, minimumLimit, maximumLimit) {
    return post(`${config.BACK_IP}/patient/${id}/limit/`, { min_limit: minimumLimit, max_limit: maximumLimit })
}

export async function addPatientChallenge(id, challengeIDSelection, limit) {
    return post(`${config.BACK_IP}/patient/${id}/challenge/${challengeIDSelection}`, {limit})
}

export async function createNewPatient(newPatient) {
    return post(`${config.BACK_IP}/register`, newPatient)
}

export async function updatePatient(newPatient, id) {
    return patch(`${config.BACK_IP}/patient/${id}`, newPatient)
}

export function deletePatient(id) {
    return del(`${config.BACK_IP}/patient/${id}`)
}

export function deletePatientPathology(id, idPatientPathology) {
    return del(`${config.BACK_IP}/patient/${id}/pathology/${idPatientPathology}`)
}

export function deletePatientChallenge(id, idPatientChallenge) {
    return del(`${config.BACK_IP}/patient/${id}/challenge/${idPatientChallenge}`)
}

export function deletePatientLimits(id) {
    return del(`${config.BACK_IP}/patient/${id}/limit/`)
}

export function deletePatientAlert(id, idPatientAlert) {
    return del(`${config.BACK_IP}/patient/${id}/alert/${idPatientAlert}`)
}

export async function getPatientQuestsData(pacient_id) {
    return get(`${config.BACK_IP}/patient/${pacient_id}/quests`)
}

export async function assignQuestToPatient(patientId, questId, timestamp) {
    return post(`${config.BACK_IP}/patient/${patientId}/quest/${questId}`, {next_time: timestamp})
}

export function deleteQuestFromPatient(patientId, questId) {
    return del(`${config.BACK_IP}/patient/${patientId}/quest/${questId}`)
}
  
  
