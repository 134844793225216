<template>
    <div>
        <Loader v-if="loading"></Loader>
        <CRow v-else>
            <CCol lg="12">
                <AbstractTable :fields="fields" :items="patients" :actions="actions" title="Pacientes"
                    :labelItemPerPage="'Pacientes por página:'"
                    :addBtn="{ enable: true, text: 'Añadir nuevo paciente', event: 'addElement' }" @addElement="addPatient"
                    @seeInfo="handleSeeInfo" @seeSessions="handleSeeSession" @seeQuests="handleSeeQuests"
                    @showDeleteModal="handleModal" :noResults="'No se han encontrado pacientes'" />
                <DeleteModal :modalContent="modalContent" @delete="deletePatientData(patientId)" :showModal="deleteModal"
                    @closeModal="closeModal" />
            </CCol>
        </CRow>
    </div>
</template>

<script>
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import Loader from "@/components/Loader.vue";
import { getPatientsData, deletePatient } from "@/services/patients";
import { limitCharacters, generateField } from '@/utils/utils';
import { roundTwoDecimals } from '@/utils/math'
export default {
    name: "patientTable",
    components: { AbstractTable, DeleteModal, Loader},
    data() {
        return {
            patients: [],
            patient: {},
            patientId: 0,
            patientName: '',
            patientSurName: '',
            loading: true,
            deleteModal: false,
            GENDER_MEN: "Hombre",
            GENDER_WOMAN: "Mujer",
            GENDER_OTHER: "Otros",
            fields: [
                generateField("name", "Nombre"),
                generateField("surname", "Apellidos"),
                generateField("gender", "Género"),
                generateField("age", "Edad"),
                generateField("weight", "Peso"),
                generateField("height", "Altura"),
                generateField("number_of_pathologies", "Número de patologías"),
                generateField("min_limit", "Limite mínimo"),
                generateField("max_limit", "Limite máximo"),
                generateField("actions", 'Acciones', { sorter: false, filter: false })
            ],
            actions: [
                {
                    key: 'information',
                    label: 'Información',
                    event: 'seeInfo',
                    classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
                },
                {
                    key: 'sessions',
                    label: 'Ver Sesiones',
                    event: 'seeSessions',
                    classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
                },
                {
                    key: 'quests',
                    label: 'Ver Cuestionarios',
                    event: 'seeQuests',
                    classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
                },
                {
                    key: 'delete',
                    label: 'Eliminar',
                    event: 'showDeleteModal',
                    classButton: 'btn btn-delete btn-sm btn-pill'
                },
            ],
            modalContent: {
                title: 'Eliminar paciente',
                textBody: '',
                buttons: [
                    {
                        textButton: 'Cerrar',
                        color: 'secondary',
                        event: 'closeModal'
                    },
                    {
                        textButton: 'Aceptar',
                        color: 'danger',
                        event: 'delete'
                    },
                ]
            }
        }
    },
    created() {
        this.receiveData();
    },
    methods: {
        onEmitMethods(event, dataDoctor) {
            switch (event) {
                case 'add': this.addPatient(dataDoctor); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        receiveData() {
            this.getPatients();
        },
        /**
        * Función en la que se obtienen los pacientes y que también
        * llama el metodo receiveData para cargarlos.
        * 
        * @returns {object[]} Los pacientes obtenidos.
        */
        getPatients() {
            getPatientsData()
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
                .then((result) => {
                    this.patients = result
                    this.patients.forEach((patient) => {
                        patient.name = limitCharacters(patient.name, 20);
                        patient.surname = limitCharacters(patient.surname, 40);
                        if (patient.gender == 'male') {
                            patient.gender = this.GENDER_MEN;
                        } else if (patient.gender == 'female') {
                            patient.gender = this.GENDER_WOMAN;
                        } else { patient.gender = this.GENDER_OTHER }
                        patient.age = (patient.age != null) ? patient.age : '-';
                        patient.weight = `${roundTwoDecimals(patient.weight)} kg`
                        patient.min_limit = (patient.min_limit == null) ? 0 : roundTwoDecimals(patient.min_limit)
                        patient.max_limit = (patient.max_limit == null) ? 0 : roundTwoDecimals(patient.max_limit)
                    });
                    this.loading = false;
                })
        },
        /**
         * Este método se ejecuta al pulsar el botón Añadir nuevo paciente y redirige a la vista del formulario para poder crear uno.
         */
        addPatient() {
            this.$router.push(`/patient/create/`);
        },
        /**
        * Método para controlar lo que se muestra en el Modal de Eliminar paciente.
        */
        handleModal(item) {
            this.patientId = item.id;
            this.modalContent.textBody = `¿Está seguro de que desea eliminar el paciente: <strong>"${item.name} ${item.surname}</strong>"? Esta acción no se puede deshacer.`,
                this.deleteModal = true;
        },
        /**
         * Método para poder ver la información del paciente.
         */
        handleSeeInfo(item) {
            this.$router.push(`/patient/info/${item.id}`);
        },
        /**
         * Método para poder ver las sesiones del paciente.
         */
        handleSeeSession(item) {
            this.$router.push(`/patient/sessions/${item.id}`);
        },
        /**
         * Método para poder ver los cuestionarios del paciente.
         */
        handleSeeQuests(item) {
            this.$router.push(`/patient/quests/${item.id}`);
        },
        /**
         * Este metodo eliminará el usuario, mandando por la URL el id
         * 
         * @param {number} id - El id del paciente a eliminar
         */
        deletePatientData(id) {
            deletePatient(id).then(() => { this.getPatients() })
            this.deleteModal = false;
        },
        /**
         * Función para cerrar el Modal.
         */
        closeModal() {
            this.deleteModal = false;
        }
    }
};
</script>