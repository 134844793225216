/** 
 * Función para redondear decimales
 */
export function roundTwoDecimals(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}
/** 
 * Función que comprueba si el valor que se obtiene contiene un digito
 */
export function hasOneDigit(val) {
    return String(Math.abs(val)).charAt(0) == val;
}